<template>
  <div class="das-container">
    <h4>Restaurants</h4>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="order-details">
              <div class="desc-img">
                <div>
                  <img :src="restaurant" alt="" />
                </div>
                <div class="res-name">Onyinyechi Ugwu</div>
              </div>
              <div>
                <div class="order-status">09/09/21</div>
              </div>
            </div>
            <div class="food-details">
              African Dishes, Sushi, Continental, Nigerian, Cousines, Pasteries,
              Fruites, drinks, Snacks, American, Outdoor and indoor cooking
            </div>

            <div class="star-rating">
              <star-rating v-model:rating="rating" v-bind:star-size="20" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="order-details">
            <div class="desc-img">
              <div>
                <img :src="restaurant" alt="" />
              </div>
              <div class="res-name">Onyinyechi Ugwu</div>
            </div>
            <div>
              <div class="order-status">09/09/2021</div>
            </div>
          </div>
          <div class="food-details">
            African Dishes, Sushi, Continental, Nigerian, Cousines, Pasteries,
            Fruites, drinks, Snacks, American, Outdoor and indoor cooking
          </div>
          <div class="star-rating">
            <star-rating v-model:rating="rating" v-bind:star-size="20" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "Sidebar",
  data() {
    return {
      restaurant: require("../../../../assets/review-pic.png"),
      // product: require("../assets/soup.png"),
    };
  },

  components: {
    StarRating,
  },
};
</script>

<style scoped>
.card {
  margin-top: 30px;
}
.address {
  padding-left: 5%;
}
.das-container h4 {
  color: rgba(4, 3, 15, 1);
  padding-top: 5%;
  font-weight: 600;
}

.desc-img {
  display: flex;
}

.das-container p {
  padding-top: 2%;
}

.details {
  display: flex;
}

.img img {
  width: 80%;
}

.img {
  background-color: rgba(241, 222, 209, 1);
  padding: 5px;
  border-radius: 50%;
  padding-left: 8px;
}

.food-details {
  padding-top: 5px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #43424c;
}

.date {
  padding-top: 7px;
  padding-left: 3%;
}

.time {
  padding-top: 7px;
  padding-left: 3%;
}

.order-details {
  display: flex;
  /* padding:0px 2%; */
  justify-content: space-between;
  width: 100%;
}

.res-name {
  font-weight: 600;
  padding-left: 15px;
}

.order-status {
  color: rgba(133, 77, 39, 1);
  font-weight: 500;
}
</style>
